<template>
  <div>

      <v-container
    id="forgot"
    class="fill-height justify-center"
    tag="section"
  >
    <!--begin::Content header-->
 
      <router-link
        class="font-weight-bold font-size-3 ml-2"
        :to="{ name: 'login' }"
      >
        Sign In!
      </router-link>
    
    <!--end::Content header-->

    <!--begin::Forgot Password-->
    <div class="login-form login-signin" v-if="emailSent">
      <div class="text-center mb-5 mb-lg-5">
        <h3 class="text-muted font-weight-semi-bold">
          Email sent to your address.
        </h3>
      </div>
    </div>

    <div class="login-form login-signin" v-if="!emailSent">
      <div class="text-center mb-5 mb-lg-5">
        <h3 class="font-size-h1">Reset Password</h3>
      </div>

      <!--begin::Form-->
      <b-form class="form" @submit.stop.prevent="onSubmit">
        <div
          role="alert"
          v-bind:class="{ show: is_error }"
          class="alert fade alert-danger"
        >
          <div class="alert-text">
            {{ err_message }}
          </div>
        </div>

        <b-form-group
          id="example-input-group-2"
          label=""
          label-for="example-input-2"
        >
          <b-form-input
            class="form-control form-control-solid h-auto py-5 px-6"
            type="password"
            id="example-input-2"
            name="example-input-2"
            v-model="$v.form.password.$model"
            :state="validateState('password')"
            aria-describedby="input-2-live-feedback"
            placeholder="Password"
          ></b-form-input>

          <b-form-invalid-feedback id="input-2-live-feedback">
            Password is required.
          </b-form-invalid-feedback>
        </b-form-group>

        <b-form-group
          id="example-input-group-3"
          label=""
          label-for="example-input-3"
        >
          <b-form-input
            class="form-control form-control-solid h-auto py-5 px-6"
            type="password"
            id="example-input-3"
            name="example-input-3"
            v-model="$v.form.confirmpassword.$model"
            :state="validateState('confirmpassword')"
            aria-describedby="input-3-live-feedback"
            placeholder="Confirm Password"
          ></b-form-input>

          <b-form-invalid-feedback id="input-3-live-feedback">
            Confirm Password is required.
          </b-form-invalid-feedback>
        </b-form-group>

        <!--begin::Action-->
        <div class="form-group d-flex flex-wrap flex-center">
          <button
            type="submit"
            ref="kt_login_signup_submit"
            class="btn btn-primary font-weight-bold px-9 py-4 my-3 font-size-3 mx-4"
          >
            Send
          </button>
          <button
            v-on:click="$router.push('pages/login')"
            class="btn btn-light-primary font-weight-bold px-9 py-4 my-3 font-size-3 mx-4"
          >
            Cancel
          </button>
        </div>
        <!--end::Action-->
      </b-form>
      <!--end::Form-->
    </div>
    <!--end::Signup-->

      </v-container>
  </div>
</template>

<style lang="scss" scoped>
.spinner.spinner-right {
  padding-right: 3.5rem !important;
}
</style>

<script>
import { mapState } from "vuex";
import { RESET_PASSWORD } from "@/core/services/store/auth.module";
import { LOGOUT } from "@/core/services/store/auth.module";

import { validationMixin } from "vuelidate";
import { email, required, minLength } from "vuelidate/lib/validators";

export default {
  mixins: [validationMixin],
  name: "resetpassword",
  data() {
    return {
      emailSent: false,
      is_error: false,
      err_message: "",
      form: {
        password: "",
        confirmpassword: ""
      }
    };
  },
  validations: {
    form: {
      password: {
        required
      },
      confirmpassword: {
        required
      },
    }
  },
  methods: {
    validateState(name) {
      const { $dirty, $error } = this.$v.form[name];
      return $dirty ? !$error : null;
    },
    onSubmit() {
      this.$v.form.$touch();
      if (this.$v.form.$anyError) {
        return;
      }

      if (this.$route.query.code == undefined || this.$route.query.code == '') {
        this.is_error = true;
        this.err_message = 'The code does not exist.';
        return;
      }

      const password = this.$v.form.password.$model;
      const confirmpassword = this.$v.form.confirmpassword.$model;
      if (password != confirmpassword) {
        this.is_error = true;
        this.err_message = 'Password and Confirm password does not match.';
        return;
      }

      // set spinner to submit button
      const submitButton = this.$refs["kt_login_signup_submit"];
      submitButton.classList.add("spinner", "spinner-light", "spinner-right");

      // dummy delay
      setTimeout(() => {
        // send email for password reset
        this.$store
          .dispatch(RESET_PASSWORD, {
            code: this.$route.query.code,
            password: password,
            passwordConfirmation: confirmpassword
          })
          .then(() => {
            this.$router.push({ path: "/dashboard" });
          });

        submitButton.classList.remove(
          "spinner",
          "spinner-light",
          "spinner-right"
        );
      }, 1000);
    }
  },
  computed: {
    ...mapState({
      errors: state => state.auth.errors
    })
  }
};
</script>
